











import QrCode from '@/components/QrCode.vue';
import UserStore from '@/store/modules/user';
import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

@Component({
  name: 'ReceiveView',
  components: {
    QrCode,
  },
})
export default class ReceiveView extends Vue {
  userStore = getModule(UserStore, this.$store);

  amount = '';

  qrCode = '';

  async created() {
    this.qrCode = await this.userStore.generateQrCode();
  }

  async generateQrCode() {
    const amount = Number(this.amount);
    if (Number.isNaN(amount) || amount === 0) {
      this.qrCode = await this.userStore.generateQrCode();
      return;
    }
    const currencyAmount = amount.toFixed(2);
    this.qrCode = await this.userStore.generateQrCode(currencyAmount);
  }
}
