








import RequestApi, { LempiRequest } from '@/common/service/RequestApi';
import RequestList from '@/components/RequestList.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'RequestsView',
  components: {
    RequestList,
  },
})
export default class RequestsView extends Vue {
  requests: LempiRequest[] = [];

  currentPage = 1;

  pages = 0;

  requestApi = new RequestApi();

  created() {
    this.listRequests();
  }

  async listRequests(page = 1) {
    const response = await this.requestApi.list(page);
    this.requests = response.results;
    this.pages = Math.ceil(response.count / 10);
  }

  changePage(page: number) {
    this.currentPage = page;
    this.listRequests(page);
  }
}
