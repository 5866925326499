




























































import { Vue, Component, Ref } from 'vue-property-decorator';
import QrScanner from 'qr-scanner';
import moneyFormat, { isValidCurrencyFormat } from '@/common/functions/moneyFormat';
import { sendMoney } from '@/common/service/WalletApi';
import NotificationService from '@/components/Notification.service';
import { getModule } from 'vuex-module-decorators';
import UserStore from '@/store/modules/user';

QrScanner.WORKER_PATH = '/qr-scanner-worker.min.js';

@Component({
  name: 'SendView',
  filters: {
    moneyFormat,
  },
})
export default class SendView extends Vue {
  @Ref('sendForm') sendForm!: HTMLFormElement;

  @Ref('video') video!: HTMLVideoElement;

  account = this.$route.query.account?.toString() || '';

  amount = this.$route.query.amount?.toString() || '';

  confirmSend = false;

  qrScanner: QrScanner | null = null;

  isProcessingQr = false;

  rules = {
    account: [(v: string) => !!v || 'Cuenta es requerida'],
    amount: [
      (v: string) => !!v || 'Cantidad es requerida',
      (v: string) => isValidCurrencyFormat(v) || 'Cantidad tiene que tener formato de moneda',
      (v: string) => Number(v) >= 0 || 'Cantidad mayor a L. 0.00',
    ],
  };

  userStore = getModule(UserStore, this.$store);

  get wallet() {
    return this.userStore.wallets[0];
  }

  async send() {
    const isValid = this.sendForm.validate();
    if (!isValid) {
      return;
    }
    if (!this.confirmSend) {
      this.confirmSend = true;
      return;
    }
    const data = await sendMoney(this.wallet.uuid, this.account, Number(this.amount));
    if (!data.isSent) {
      NotificationService.show({
        text: data.error.message,
        color: 'error',
      });
      return;
    }
    this.$router.push({ name: 'app-home' });
  }

  cancelSend() {
    this.confirmSend = false;
  }

  openCamera() {
    this.isProcessingQr = true;
    if (this.qrScanner) {
      this.qrScanner.start();
      return;
    }
    setTimeout(() => {
      if (!this.video.parentNode) {
        return;
      }
      this.qrScanner = new QrScanner(this.video, this.processQr);
      const canvas: HTMLCanvasElement = (
        this.qrScanner as unknown as { $canvas: HTMLCanvasElement }
      ).$canvas;
      canvas.style.width = '100%';
      this.video.parentNode.insertBefore(canvas, this.video);
      this.qrScanner.start();
    });
  }

  closeCamera() {
    this.isProcessingQr = false;
    if (this.qrScanner) {
      this.qrScanner.stop();
    }
  }

  processQr(result: string) {
    let url: URL;
    try {
      url = new URL(result);
    } catch (error) {
      return;
    }
    this.amount = url.searchParams.get('amount') || '';
    this.account = url.searchParams.get('account') || '';
    this.closeCamera();
  }
}
